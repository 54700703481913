<template>
  <div class="about">
      <h1>About Meetingplanner</h1>
      <p>
          Meetingplanner is developed by Erik Huelsmann as an experiment
          in combining Vue front-end tooling with the Perl development workflow,
          at the same time solving the problem of being short of a good, privacy
          respecting meeting planner web application.
      </p>
  </div>
</template>


<style scoped>
.about {
    margin-left: auto;
    margin-right: auto;
    min-width: 20em;
    max-width: 40em;
}
.about p {
    text-align: left;
}
</style>
